@use '@compliance.ai/styles/dist/variables';

$status-width: 9.5rem;
$table-height: 19rem;

.requirement-taxonomy {
  display: flex;
  flex-direction: column;
  gap: variables.$spacing-l;
  margin-top: variables.$spacing-xs;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__tilte {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: variables.$spacing-l;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      gap: variables.$spacing-l;
    }

    &__status {
      min-width: $status-width;

      & > div {
        padding-left: variables.$spacing-xs;

        & .concept-status__status__item {
          color: variables.$primary-color-2;
        }
      }

      p {
        display: flex;
        align-items: center;
        gap: variables.$spacing-semi-xs;
      }

      &__item {
        display: flex;
        align-items: center;
        gap: variables.$spacing-semi-xs;
      }

      &__active {
        & > div {
          background-color: variables.$secondary-color-3;
        }
      }

      &__deactivated {
        & > div {
          background-color: variables.$secondary-color-5;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: variables.$spacing-l;

    &__text_info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      gap: variables.$spacing-xxl;

      &__field {
        flex: 1;
      }
    }

    &__option {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-evenly;
    }

    &__table {
      max-height: $table-height;
      overflow-y: scroll;
    }
  }
}

div[role='tooltip'] > div > div {
  & p {
    color: variables.$primary-color-2;
  }

  &:has(.active) {
    & > div > div:hover {
      background-color: variables.$secondary-color-14;
      opacity: 0.8;
    }

    background-color: variables.$secondary-color-3;
  }

  &:has(.deactivated) {
    & > div > div:hover {
      background-color: variables.$secondary-color-13;
      opacity: 0.8;
    }

    background-color: variables.$secondary-color-5;
  }
}
